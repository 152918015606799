<template>
<div>
<van-nav-bar
      class="backBox"
      title="服务变更续费"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="box_wrap">
        <div class="inner_box">
          <div class="box">
            <div class="box_header">
              <span class="type_name"
                >{{serveInfo.vh_cate_name}}/{{serveInfo.month_rent / 100}}元</span
              >
              <!-- <span class="type_name" v-if="serveInfo.type == 2"
                >工作日白天包月卡/300元</span
              >
              <span class="type_name" v-if="serveInfo.type == 3"
                >夜间包月卡/150元</span
              > -->
              <span class="type_date">{{ serveInfo.effective_date_end }} 到期</span>
            </div>
            <!-- <span class="btn_text" @click="openRenew">续费</span> -->
            <div class="group_5">
              <!-- <span class="text_5" v-if="serveInfo.type == 1 && payNum == 1"
                >{{ monthTypeList[0].value }}元</span
              > -->
              <!-- <span class="text_5" v-if="serveInfo.type == 2 && payNum == 1"
                >{{ monthTypeList[1].value }}元</span
              >
              <span class="text_5" v-if="serveInfo.type == 3 && payNum == 1"
                >{{ monthTypeList[2].value }}元</span
              > -->
              <span class="text_5">{{ payMoney.toFixed(2) }}元</span>
              <!-- 计算后金额{{}} -->
              <div class="group_6">
                <div class="section_2">
                  <div class="text-wrapper_1">
                    <van-stepper v-model="payNum" @change="onChangeStep" />
                  </div>
                </div>
                <span class="text_8">月</span>
              </div>
            </div>
          </div>

          <div class="lineline"></div>
          <div class="tip_box">
            <span class="tip_box_tit">温馨提示：</span>
            <span class="tip_box_text"
              >1.套餐显示为单个车位的价格，支付总金额按照本次办理的约束<br />或者周数叠加计算</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="pay_button"> -->
      <van-button
        class="pay_button"
        type="info"
        color="##4470FC"
        @click="onClickPay"
        :loading="payBtnLoading" loading-text="加载中..."
        >变更</van-button
      >
      <!-- </div> -->
    </div>
    <!-- 绑定手机 -->
    <!-- <bind-phone
      :show="show_bind_phone"
      @get-success="getBindPhoneSuccess"
      @get-on-close="getBindPhoneShow"
    ></bind-phone> -->
    <!-- 未授权或者未绑定手机的提示弹窗 -->
    <!-- <not-auth-or-phone
      :show="showPhonePopupInNotIndex"
      :show-type="showNotAuthPhoneType"
      @get-on-close="getNotAuthBindPhoneShow"
      @showBindPhone="showBindPhone"
    ></not-auth-or-phone> -->
  </div>
  </div>
</template>
<script>
import checkPhoneMixin from '../../../mixins/checkPhone'
import BindPhone from '../../../components/BindPhone.vue'
import NotAuthOrPhone from '../../../components/NotAuthOrPhone.vue'

import { Toast, Dialog } from 'vant'
import * as api from '../../../api/api'
import * as constant from '../../../api/constant'
import * as common from '../../../api/common'
import * as util from '../../../api/util'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
export default {
  mixins: [checkPhoneMixin],
  components: { BindPhone, NotAuthOrPhone },
  data () {
    return {
      serveInfo: {
        // 预留 获取当前服务信息 单价等
      },
      columnsSel: '',
      showVhPopup: false,
      columns: [
        '浙A12345',
        '浙B12345',
        '浙C12345',
        '浙D12345',
        '浙F12345'
      ],
      showPhonePopupInNotIndex: false,
      show_bind_phone: false,
      showNotAuthPhoneType: 2,
      payMoney: 0,
      payNum: 1,
      payTypeLocal: '',
      payBtnLoading: false,
      code: '',
      sidLocal: 'yuen03',
      tempBack: {
        expireMillSeconds: 240 * 1000,
        timeout: null,
        timestamp: 0
      }

    }
  },
  created () {
    const renewParaStorage = sessionStorage.getItem(constant.keyRenewParaStorageSession)
    console.log('created-renewParaStorage,', renewParaStorage)
    this.serveInfo = JSON.parse(renewParaStorage)
    console.log('this.serveInfo', this.serveInfo)
    this.payMoney = Number(this.serveInfo.month_rent) / 100 * 1
    if (common.getUserAgent() === common.userAgentInfo.wx) {
      this.code = this.$route.query.code
    }
  },
  mounted () {
    const renewParaStorage = sessionStorage.getItem(constant.keyRenewParaStorageSession)
    console.log('mounted-renewParaStorage,', renewParaStorage)
    // 每个页面都要 引入下面的混入
    // 监听混入中触发的事件，显示弹窗
    this.$on('showPhonePopup', () => {
      this.showPhonePopupInNotIndex = true
    })
    // ToDo 监听混入中触发的事件，手机号已绑定 则关闭打开的弹窗
    this.$on('phoneHaveBind', () => {
      if (this.show_bind_phone) {
        this.show_bind_phone = false
      }
      if (this.showPhonePopupInNotIndex) {
        this.showPhonePopupInNotIndex = false
      }
    })
    this.isVueReady = true
    console.log('当前介质是微信还是支付宝', common.getUserAgent())
    this.setButtonClertload()
    if (common.getUserAgent() === common.userAgentInfo.wx) {
      this.WX_Bridge_ready(() => {
        this.setButtonClertload()
      })
    } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
      this.ZFBReady(() => {
        this.setButtonClertload()
      })
    }
    // 判断是否需要进行支付
    const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
    // const payTypeLocal = sessionStorage.getItem(constant.keyPayTypeSession)
    // console.log('payTypeLocal', payTypeLocal)
    console.log('this.thePayType', this.thePayType)
    console.log('this.sid', this.sid)
    console.log('this.openID', this.openID)
    if (sessionStorageLocal && this.thePayType) {
      // 数据回显
      // this.plateNumber = sessionStorageLocal.plate_number.split('')
      // this.radioSelVal = Number(sessionStorageLocal.vh_cate_id)
      // this.monthNum = Number(sessionStorageLocal.month)
      // this.waitPayMoney = Number(sessionStorageLocal.monthly_rent_fee)
      // this.payTypeLocal = payTypeLocal
      this.serveInfo = sessionStorageLocal
      this.payNum = sessionStorageLocal.month_number
      this.payMoney = Number(this.serveInfo.month_rent) / 100 * this.payNum

      // TODO 已经点击支付且code成功拿回 可以请求支付接口
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      this.doPay()
      return
    }
    this.getTempBackTimeout()
  },
  computed: {
    ...mapGetters(['vhColumns', 'openID', 'thePayType']),
    ...mapState({
      // payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      appid: state => state.appid.appid
    })
  },
  methods: {
    getTempBackTimeout () {
      const ms = this.tempBack.expireMillSeconds
      if (!this.tempBack.timestamp) this.tempBack.timestamp = new Date().getTime()
      // console.log('tempBack.timestamp', this.tempBack.timestamp)
      this.tempBack.timeout = setTimeout(() => {
        this.expireGoBack()
        this.clearBackTimeout()
      }, ms)
    },
    clearBackTimeout () {
      clearTimeout(this.tempBack.timeout)
      this.tempBack.timeout = null
    },
    expireGoBack () {
      this.tempBack.timestamp = 0
      this.$dialog.alert({
        title: '支付超时',
        message: '点击“确认”后重新付款'
      }).then(() => {
        this.setDataWhenErrorConfirm()
      })
    },
    clearSessionData () {
      sessionStorage.removeItem(constant.keyBusinessInfoSession)
      sessionStorage.removeItem(constant.keyRenewParaStorageSession)
    },
    setButtonClertload () {
      this.$nextTick(() => {
        this.isTypeReady = true
      })
    },
    wxReadyToPay (payargs) {
      console.log('wxReadyToPay---', payargs)
      var that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          // console.log('vv 111')
          document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(payargs), false)
        } else if (document.attachEvent) {
          // console.log('vv 222')
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(payargs))
          document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(payargs))
        }
      } else {
        // console.log('vv 333')
        that.onBridgeReady(payargs)
      }
    },
    onBridgeReady (payargs) {
      console.log('onBridgeReady---', payargs)
      console.log('onBridgeReady---', this.thePayType, typeof this.thePayType)
      var that = this

      const payType = Number(this.thePayType)
      if (payType === common.payType.wx) { // 微信直联
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.bjyz) { // 微信云纵间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.trade_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.pp) { // 微信PP间联
        console.log('wx pp', payargs)
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        this.payBtnLoading = false
        window.location.replace(payargs)
        // pay_url: "https://auth.4pyun.com/authorize?scope=snsapi_base&redirect_uri=https%3A%2F%2Fapp.4pyun.com%2Fpayment%2Ftrade%2Fcreate%3Fpay_id%3D983768955900399617"

        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.abc) { // 微信农行间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.lanzhoubank) { // 兰州银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.billQRCode)
      } else if (payType === common.payType.ningbobank) { // 宁波银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
      }
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    doPay () {
      const that = this
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
      const grossPrice = parseFloat(this.payMoney) * 100
      const actuallyPaid = parseFloat(this.payMoney) * 100
      const subject = sessionStorageLocal.plate_number + '月租车服务续费'
      // TODO
      common.getPayargsMonth(this.sid, grossPrice, actuallyPaid, Number(this.thePayType), subject, sessionStorageLocal, this.code)
        .then(payargs => {
          that.$toast.clear()
          that.isPress = false
          console.log('getPayargsMonth----', payargs)
          if (!payargs) {
            that.$dialog.alert({
              title: '支付失败'
            }).then(() => {
              that.setDataWhenErrorConfirm()
            })
            return
          }
          if (common.getUserAgent() === common.userAgentInfo.wx) {
            that.wxReadyToPay(payargs)
          } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
            that.do_zfb_pay(payargs)
          }
        })
        .catch(e => {
          console.log('获取支付平台res', e)
          that.$toast.clear()
          that.isPress = false
          if (parseFloat(grossPrice) > 0) {
            console.log(e)
            that.$dialog.alert({
              // showCancelButton: true,
              title: '支付失败',
              message: e.msg
            }).then(() => {
              // that.onClickLeft()
              that.setDataWhenErrorConfirm()
            })
          } else that.$toast.clear()
        })
    },
    async getPayType () {

    },
    onChangeStep () {
      console.log('数量', this.payNum)
      console.log('单价', this.serveInfo.month_rent)
      this.payMoney = this.payNum * this.serveInfo.month_rent / 100
    },
    getBindPhoneSuccess () {

    },
    getBindPhoneShow () {

    },
    getNotAuthBindPhoneShow () {

    },
    showBindPhone () {
      this.show_bind_phone = true
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    setDataWhenErrorConfirm () {
      this.payBtnLoading = false
      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))

      console.log('获取到的code：', this.code)
      console.log('获取到的code：', this.$route.query.code)
      // TODO 点击支付获取code后会重新来到该页面 走挂载，点击支付后需要设置是否点击了支付按钮的标志，挂载中判断是否已经点击，
      // todo 已经点击，且有code，则直接访问支付接口获取支付回调地址。否则不执行
      if (sessionStorageLocal) {
      // 数据回显
      // this.plateNumber = sessionStorageLocal.plate_number.split('')
        this.payNum = Number(sessionStorageLocal.month)
        this.payMoney = Number(sessionStorageLocal.monthly_rent_fee) / 100 * this.payNum
      }
    },
    showPopup () {
      this.showVhPopup = true
    },
    async onClickPay () {
      if (!this.sid) {
        return Toast.fail('车场编号丢失，请返回重试')
      }
      if (this.payMoney <= 0) {
        return Toast.fail('应付金额为0')
      }
      if (!this.isVueReady) {
        return this.$toast('努力加载中.')
      } else if (!this.isTypeReady) {
        if (common.getUserAgent() === common.userAgentInfo.wx) {
          return this.$toast('微信努力加载中.')
        } else {
          return this.$toast('支付宝努力加载中.')
        }
      }
      this.payBtnLoading = true
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      // let payWay = common.payWay.weichat
      // if (common.getUserAgent() === 'wx') {
      //   payWay = common.payWay.weichat
      // } else if (common.getUserAgent() === 'zfb') {
      //   payWay = common.payWay.alipay
      // }
      const businessInfo = {
        business_type: constant.monthRentInfos.businessType,
        charge_type: constant.monthRentInfos.chargeType.vhRenew,
        plate_number: this.serveInfo.plate_number,
        vh_cate_id: this.serveInfo.vh_cate_id,
        vh_type_id: 1,
        month: this.payNum,
        charge_start_time: util.getNextDayStart(this.serveInfo.effective_date_end),
        monthly_rent_fee: this.serveInfo.month_rent,
        month_number: this.payNum
      }
      sessionStorage.setItem(constant.keyBusinessInfoSession, JSON.stringify(businessInfo))
      // sessionStorage.setItem(constant.keyPayTypeSession, this.thePayType)
      // 微信或支付宝 code, type=1、2、41 时必须获取上传 code
      if (this.thePayType === common.payType.wx || this.thePayType === common.payType.zfb || this.thePayType === common.payType.abc || this.thePayType === common.payType.abc_zfb) {
        // 需要code
        // 获取code
        this.getAllCode()
      } else {
        this.code = null
        this.doPay()
      }
    },
    getAllCode () {
      if (common.getUserAgent() === common.userAgentInfo.wx) {
        console.log('wx getcode')
        this.getAppId().then(appid => {
          window.location.href = common.getCodeObtainUrl(window.location, appid, true)
          console.log('window.location.href', window.location.href)
        }).catch(e => {
          this.$toast('网络错误')
        })
      } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
        if (!this.code) {
          this.getZFBAppId().then(appid => {
            window.location.href = common.getCodeObtainUrl(window.location, appid, false)
          }).catch(e => {
            this.$toast('网络错误')
          })
        }
      }
      // console.log('22222222222222')
    },
    async getAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid || this.$route.query.sid
          // sid: this.sidLocal
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          // console.log('getAppId', d)
          this.appidLocal = d.app_id
          // this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },
    onVhChange () {

    },
    onVhConfirm (data) {
      console.log('选择车牌', data)
      if (data) {
        this.columnsSel = data
        this.showVhPopup = false
      }
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    onVhClose () {
      this.showVhPopup = false
    }

  }
}
</script>
<style lang="scss" scoped>
.page {

}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  // padding: 1rem;
  .box_wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    .inner_box {
      padding: 0.16rem;
      background-color: #fff;

      .box {
        width: 3.43rem;
        margin-right: 0.01rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .box_header {
          display: flex;
          flex-direction: column;
          .title {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: .16rem;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-right: 0.51rem;
          }
          .type_name {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-top: 0.16rem;
          }
          .type_date {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.12rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.17rem;
            margin-top: 0.02rem;
          }
        }
        .group_5 {
          display: flex;
          flex-direction: column;
          .text_5 {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: right;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-left: 0.18rem;
          }
          .group_6 {
            margin-top: 0.09rem;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .section_2 {
              margin-bottom: 0.02rem;
              display: flex;
              flex-direction: column;
              .text-wrapper_1 {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                margin: 0 0.03rem 0 0.09rem;
              }
            }
            .text_8 {
              overflow-wrap: break-word;
              color: rgba(0, 0, 0, 1);
              font-size: 0.14rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.2rem;
            }
          }
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
          margin-top: 0.36rem;
        }
      }
      .lineline {
        width: 3.43rem;
        height: 0.01rem;
        background-color: #f4f4f4;
        margin-top: 0.16rem;
      }
      .vh_box {
        width: 3.43rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.16rem 0.01rem 0 0;
        .type_name {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .btn_text {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
      .tip_box {
        display: flex;
        flex-direction: column;
        margin: 0.25rem 0.12rem 0 0;
        .tip_box_tit {
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
          margin-right: 2.77rem;
        }
        .tip_box_text {
          width: 3.32rem;
          height: 0.34rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
          margin-top: 0.08rem;
          margin-bottom: 0.24rem;
        }
      }
    }
  }
  .pay_button {
    width: 90%;
    border-radius: 0.04rem;
  }
}
</style>
